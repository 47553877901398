@tailwind base;
@tailwind components;
@tailwind utilities;

/* The typing effect */
@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}

@media (max-width: 500px) {
  .swiper_container {
    height: 47rem;
  }
  .swiper-slide {
    width: 28rem !important;
    height: 36rem !important;
  }
  .swiper-slide img {
    width: 28rem !important;
    height: 36rem !important;
  }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: theme('colors.spindle.400'); }
}
@layer base {
  hr {
    @apply my-6 border-x border-b border-spindle-400/20 drop-shadow-lg max-w-screen-lg	mx-auto;
  }
  h1 {
    @apply lg:text-6xl text-5xl font-display font-bold  pb-4 text-spindle-50 text-shadow-md;
  }
  h2,
  .homepage span {
    @apply lg:text-4xl text-2xl font-display font-bold tracking-normal text-spindle-50 text-shadow-md capitalize;
  }
  h3 {
    @apply lg:text-3xl text-lg font-display tracking-normal text-spindle-50 text-shadow-md capitalize;
  }
  h4 {
    @apply text-2xl font-display tracking-normal text-spindle-50 text-shadow-md capitalize;
  }
  small {
    @apply text-base font-semibold text-bermuda-950 font-display;
  }
  p {
    @apply text-base leading-8 text-spindle-100;
  }
  input, textarea{
    @apply mt-1 block w-full px-3 py-4 bg-spindle-50/30 border border-spindle-300 rounded-3xl text-base text-spindle-50 shadow-sm placeholder-spindle-50
      focus:outline-none focus:border-bermuda-300 focus:ring-1 focus:ring-bermuda-300
      invalid:border-red-500 invalid:text-red-300
      focus:invalid:border-red-500 focus:invalid:ring-red-500
  }
}
@layer components {
    .card {
      @apply rounded-3xl  drop-shadow-lg bg-spindle-200/10 border border-solid border-spindle-400/20;
    }
    
    /* The sliding underline effect for navlinks */
    .link-container {
      @apply relative after:content-[''] after:absolute after:w-0 after:h-[3px] after:bg-bermuda-300 after:transition-[width] after:duration-[0.3s] after:ease-[ease] after:left-0 after:top-[54px] hover:after:w-full;
    }
    .cta-btn {
      @apply px-5 py-3 font-display font-semibold uppercase leading-6 text-xl shadow-2xl  text-bermuda-50 focus-visible:outline-offset-2 rounded-full bg-gradient-to-br from-spindle-400 to-spindle-600 hover:text-white hover:from-spindle-500 hover:to-spindle-700 active:from-spindle-600 active:to-spindle-800 focus-visible:outline-spindle-700 transition-colors duration-200 tracking-wider focus:outline-none focus:ring focus:ring-spindle-700 focus:ring-opacity-80;
    }
    .btn-primary {
      @apply w-48 px-6 py-3  text-lg font-medium  text-bermuda-50 uppercase font-display  leading-6  shadow transform focus-visible:outline-offset-2 rounded-full bg-gradient-to-bl from-spindle-500 to-spindle-700 hover:from-spindle-600 hover:to-spindle-800 active:from-spindle-700 active:to-spindle-900 focus-visible:outline-spindle-800 tracking-wider focus:outline-none focus:ring focus:ring-spindle-800 focus:ring-opacity-80  ease-in-out duration-200;
    }
    .btn-outline {
      @apply px-5 py-3  text-xl font-medium  uppercase font-display  leading-6  shadow-2xl  focus-visible:outline-offset-2 rounded-full bg-bermuda-50 text-bermuda-500 hover:bg-white hover:text-bermuda-600 active:text-bermuda-700 focus-visible:outline-bermuda-700 tracking-wider focus:outline-none focus:ring focus:ring-bermuda-700 focus:ring-opacity-80   duration-200 border-bermuda-700;
    }
    .h2-icon {
      @apply h-8 w-8 text-spindle-50;
    }
    .contact-label {
      @apply inline-flex lg:items-start items-center mb-6 mt-4 flex-col align-middle;
    }
    .contact-label h2 {
      @apply flex gap-2 items-center;
    }
    .contact-label p {
      @apply mt-2;
    }
    .phoneSvg {
      @apply stroke-spindle-100 absolute m-auto -z-10;
    }
    .typewriter {
      @apply whitespace-nowrap animate-[typing_3.5s_steps(40,end),blink-caret_0.75s_step-end_infinite] mx-auto my-0 border-r-[0.115em] border-r-spindle-300 border-solid !lowercase;
    }
    .green-card {
      @apply card bg-bermuda-200 opacity-95;
    }
  }